import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  getDoc,
  doc,
  addDoc,
  Timestamp,
  orderBy,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

/* ------------------------------ */
/* Events */
/* ------------------------------ */

export async function getNextEvents() {
  const snapshot = await getDocs(
    query(
      collection(db, "events"),
      where("endDate", ">", Timestamp.fromDate(new Date())),
      orderBy("endDate")
    )
  );
  const list = snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return list;
}

export async function getEvents() {
  const snapshot = await getDocs(
    query(collection(db, "events"), orderBy("startDate", "desc"))
  );
  const list = snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return list;
}

export async function searchEvents(keyword) {
  const coll = collection(db, "events");
  const query_ = query(
    coll,
    where("parentEventId", "==", ""),
    where("title", ">=", keyword.toUpperCase()),
    where("title", "<=", keyword.toLowerCase() + "\uf8ff")
  );
  const snapshot = await getDocs(query_);
  const list = snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return list;
}

export async function searchEventsOptions(keyword) {
  const data = await searchEvents(keyword);

  return data.map((item) => ({
    ...item,
    label: item.title,
  }));
}

export async function getEvent(id) {
  const ref = doc(db, "events", id);
  const snapshot = await getDoc(ref);

  if (!snapshot.exists()) return null;

  return {
    id,
    ...snapshot.data(),
  };
}

export async function getEventSchedule(id) {
  const ref = collection(db, "events", id, "schedule");
  const snapshot = await getDocs(ref);
  const list = snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return list;
}

export async function addEvent(params) {
  const data = {
    ...params,
    startDate: Timestamp.fromDate(new Date(params.startDate)),
    endDate: Timestamp.fromDate(new Date(params.endDate)),
  };

  const docRef = await addDoc(collection(db, "events"), data);

  return docRef.id;
}

export async function addEventSchedule(eventId, params) {
  const data = {
    ...params,
    startDate: Timestamp.fromDate(new Date(params.startDate)),
    endDate: Timestamp.fromDate(new Date(params.endDate)),
  };

  const docRef = await addDoc(
    collection(db, "events", eventId, "schedule"),
    data
  );

  return docRef.id;
}

export async function getEventCategories() {
  const coll = collection(db, "events-categories");
  const query_ = query(coll);
  const snapshot = await getDocs(query_);
  const list = snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return list;
}

export async function searchEventCategories(keyword) {
  const coll = collection(db, "events-categories");
  const query_ = query(
    coll,
    where("title", ">=", keyword.toUpperCase()),
    where("title", "<=", keyword.toLowerCase() + "\uf8ff")
  );
  const snapshot = await getDocs(query_);
  const list = snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return list;
}

export async function searchEventCategoriesOptions(keyword) {
  const data = await searchEventCategories(keyword);

  return data.map((item) => ({
    ...item,
    label: item.title,
  }));
}

export async function searchTeam(keyword) {
  const coll = collection(db, "team");
  const query_ = query(
    coll,
    where("name", ">=", keyword.toUpperCase()),
    where("name", "<=", keyword.toLowerCase() + "\uf8ff")
  );
  const snapshot = await getDocs(query_);
  const list = snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return list;
}

export async function searchTeamOptions(keyword) {
  const data = await searchTeam(keyword);

  return data.map((item) => ({
    ...item,
    label: item.name,
  }));
}

/* ------------------------------ */
/* Team */
/* ------------------------------ */

export async function getTeam() {
  const snapshot = await getDocs(query(collection(db, "team")));
  const list = snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return list;
}

export async function getTeamMember(id) {
  const ref = doc(db, "team", id);
  const snapshot = await getDoc(ref);

  if (!snapshot.exists()) return null;

  return {
    id,
    ...snapshot.data(),
  };
}

export async function addTeamMember(params) {
  const data = { ...params };

  const docRef = await addDoc(collection(db, "team"), data);

  return docRef.id;
}

/* ------------------------------ */
/* Media */
/* ------------------------------ */

export async function getMediaCategories() {
  const snapshot = await getDocs(query(collection(db, "media")));
  const list = snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return list;
}

export async function getMediaCategory(id) {
  const ref = doc(db, "media", id);
  const snapshot = await getDoc(ref);

  if (!snapshot.exists()) return null;

  return {
    id,
    ...snapshot.data(),
  };
}

export async function getMediaCategoryItems(id) {
  const ref = collection(db, "media", id, "items");
  const snapshot = await getDocs(query(ref, orderBy("createdAt", "desc")));
  const list = snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return list;
}

export async function addMediaItem(categoryId, params) {
  const data = {
    ...params,
    createdAt: Timestamp.fromDate(new Date()),
  };

  const docRef = await addDoc(
    collection(db, "media", categoryId, "items"),
    data
  );

  return docRef.id;
}

export async function searchMediaCategories(keyword) {
  const coll = collection(db, "media");
  const query_ = query(
    coll,
    where("title", ">=", keyword.toUpperCase()),
    where("title", "<=", keyword.toLowerCase() + "\uf8ff")
  );
  const snapshot = await getDocs(query_);
  const list = snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return list;
}

export async function searchMediaCategoriesOptions(keyword) {
  const data = await searchMediaCategories(keyword);

  return data.map((item) => ({
    ...item,
    label: item.title,
  }));
}

export const getUserId = () => {
  const user = auth.currentUser;
  if (!user) return null;

  return user.uid;
};

export const joinEvent = async (params) => {
  const userId = getUserId();

  const data = {
    ...params,
    createdAt: Timestamp.fromDate(new Date(params.createdAt)),
  };

  await setDoc(doc(db, "events", data.eventId, "attendees", userId), data);

  return userId;
};

export const toggleOffer = async (id, status) => {
  await updateDoc(doc(db, "events", id), { offerVisible: status });
};

export const toggleOfferSchedule = async (id, scheduleId, status) => {
  await updateDoc(doc(db, "events", id, "schedule", scheduleId), {
    offerVisible: status,
  });
};
