import { Helmet } from "react-helmet";

export default function SocialTags({ title, cover }) {
  return (
    <Helmet>
      <meta
        name="description"
        content={`Urmărește LIVE evenimentul ${title}`}
      />
      <meta property="og:url" content="https://live.restartix.ro" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="RestartiX - Urmărește LIVE" />
      <meta
        property="og:description"
        content={`Urmărește LIVE evenimentul ${title}`}
      />
      <meta property="og:image" content={cover} />
      <meta property="og:site_name" content="RestartiX - Urmărește LIVE" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="https://live.restartix.ro" />
      <meta name="twitter:title" content="RestartiX - Urmărește LIVE" />
      <meta
        name="twitter:description"
        content={`Urmărește LIVE evenimentul ${title}`}
      />
      <meta name="twitter:image" content={cover} />
    </Helmet>
  );
}
