import { Box } from "@mui/material";

export default function YoutubePlayer({ id }) {
  if (!id) return null;

  return (
    <Box
      sx={{
        position: "relative",
        paddingBottom: "56.25%",
        maxWidth: "100%",

        iframe: {
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          border: 0,
        },
      }}
    >
      <iframe
        title="Live"
        src={`https://www.youtube.com/embed/${id}?autoplay=1`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        autoPlay={true}
      ></iframe>
    </Box>
  );
}
