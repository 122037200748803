import { Box, CircularProgress, styled } from "@mui/material";
import useFirebase from "./useFirebase";

const PageContainer = styled(Box)(({ theme }) => ({
  textAlign: "center",
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const PageLoading = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexGrow: 1,
}));

export default function FirebaseProvider({ children }) {
  const { isLoading } = useFirebase();

  if (isLoading) {
    return (
      <PageContainer>
        <PageLoading>
          <CircularProgress />
        </PageLoading>
      </PageContainer>
    );
  }

  return children;
}
