/* eslint-disable */

export function toBase64URL(e) {
  var a = btoa(JSON.stringify(e));
  return a.replace(/\+/g, "-").replace(/\//g, "_").replace(/\=+$/, "");
}

export function generateJWT(e, a, r) {
  var n = { alg: "none" },
    t = { sub: e, name: a };
  return r && (t.picture = r), toBase64URL(n) + "." + toBase64URL(t) + ".";
}
