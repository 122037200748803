import { useEffect, useRef, useState } from "react";
import {
  onAuthStateChanged,
  signInAnonymously,
  signInWithCustomToken,
} from "firebase/auth";
import { auth } from "./firebase";

export default function useFirebase() {
  const [isLoading, setIsLoading] = useState(true);
  const started = useRef(null);

  useEffect(() => {
    const doLogin = async () => {
      setIsLoading(true);

      onAuthStateChanged(auth, async (user) => {
        if (user) {
          setIsLoading(false);
        } else {
          const token = window.localStorage.getItem("userToken");

          if (token) {
            signInWithCustomToken(auth, token)
              .then((res) => {
                setIsLoading(false);
              })
              .catch((error) => {
                setIsLoading(false);
              });
          } else {
            signInAnonymously(auth)
              .then((res) => {
                setIsLoading(false);
              })
              .catch((error) => {
                setIsLoading(false);
              });
          }
        }
      });
    };

    if (!started.current) {
      doLogin();
      started.current = true;
    }
  }, []);

  return { isLoading };
}
